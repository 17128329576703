'use client';

import PageContainer from '@/components/pageContainer';
import Header from '@/components/layouts/defaultLayout/header';
import NavLink from '@/components/navLink';
import { ROUTES } from '@/routes';
import { DAVINCI_CDN } from './_constants/defaultValues';
import Image from 'next/image';

const Page = () => {
  return (
    <>
      <Header />
      <PageContainer>
        <div className="bg-neutral-surface pb-32 pt-16">
          <div className="grid grid-cols-[1fr] justify-items-center gap-y-10">
            <div className="relative h-56 w-56 md:h-72 md:w-72 lg:h-80 lg:w-80">
              <Image src={`${DAVINCI_CDN}/elty/404.jpg`} alt="not found" fill />
            </div>
            <h1 className="text-center text-xl font-semibold text-neutral-darker md:text-3xl lg:text-4xl">
              Oops! Hai trovato una pagina <br className="block lg:hidden" /> che non esiste
            </h1>
            <p className="text-center text-base font-normal text-neutral-lighter md:text-xl lg:text-2xl">
              Non preoccuparti, torna alla home e ti
              <br />
              aiuteremo a trovare quello che cerchi
            </p>
            <NavLink href={ROUTES.HOME.pathname} variant="filled" color="secondary" aria-label="Torna alla home" label="Torna alla home" />
          </div>
        </div>
      </PageContainer>
    </>
  );
};

export default Page;
